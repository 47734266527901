import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlayView from "../views/player/PlayView.vue";
import AdminView from "@/views/admin/AdminView.vue";
import adminRoutes from "@/views/admin/adminRoutes";
import InviteView from "@/views/player/InviteView.vue";
import rawUsername from "@/rawInfo/rawUsername.vue";
import rawPoints from "@/rawInfo/rawPoints.vue";
import rawAnswer from "@/rawInfo/rawAnswer.vue";
import rawQuestion from "@/rawInfo/rawQuestion.vue";
import rawQuestionAnswer from "@/rawInfo/rawQuestionAnswer.vue";
import rawBuzzer from "@/rawInfo/rawBuzzerd.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/invite/:gameId/:secret/",
    name: "invite",
    component: InviteView,
    props: true,
  },
  {
    path: "/play/:id?",
    name: "play",
    component: PlayView,
    props: true,
  },
  {
    path: "/raw/:userId/username/",
    component: rawUsername,
    meta: {
      useRawLayout: true,
    },
    props: true,
  },
  {
    path: "/raw/:userId/points/",
    component: rawPoints,
    meta: {
      useRawLayout: true,
    },
    props: true,
  },
  {
    path: "/raw/:userId/answer/",
    component: rawAnswer,
    meta: {
      useRawLayout: true,
    },
    props: true,
  },
  {
    path: "/raw/:userId/buzzer/",
    component: rawBuzzer,
    meta: {
      useRawLayout: true,
    },
    props: true,
  },
  {
    path: "/raw/answer/",
    component: rawQuestionAnswer,
    meta: {
      useRawLayout: true,
    },
  },
  {
    path: "/raw/question/",
    component: rawQuestion,
    meta: {
      useRawLayout: true,
    },
  },
  {
    path: "/admin",
    component: AdminView,
    children: adminRoutes,
  },
  {
    path: "*",
    name: "*",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
