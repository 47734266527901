<template>
  <div>
    <v-form>
      <v-text-field
        v-model="description"
        filled
        label="Beschreibung"
      ></v-text-field>
    </v-form>
    <div class="d-flex flex-row">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="add">
        <v-icon class="mr-2">fas fa-plus</v-icon>
        Hinzufügen
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "AddGameModal",
  data() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    return {
      description: `Stream vom ${this.$d(date)}`,
    };
  },
  methods: {
    async add() {
      await this.axios.post("/admin/game/create", {
        description: this.description,
      });
      this.$emit("refreshGames");
      this.$emit("closeModal");
    },
  },
  mounted() {
    this.$emit;
  },
});
</script>
