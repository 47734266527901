<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-3" :color="isEdit ? '' : 'success'">fas fa-plus</v-icon>
      <span class="font-weight-bold"> | </span>
      <v-icon class="ml-3 mr-5" :color="isEdit ? 'primary' : ''">
        fas fa-pen
      </v-icon>
      <div v-if="isEdit">Editieren</div>
      <div v-else>Erstellen</div>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              filled
              ref="qTextArea"
              name="question"
              label="Frage"
              rows="1"
              v-model="editItem.question"
              counter="200"
              @keypress.enter.prevent="editOrCreate"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              name="question"
              label="Antwort"
              rows="1"
              counter="100"
              v-model="editItem.answer"
              @keypress.enter.prevent="editOrCreate"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              hide-details
              filled
              label="Punkte bei korrekter Antwort"
              type="number"
              v-model="editItem.correctAnswerPoints"
              @keypress.enter.prevent="editOrCreate"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              hide-details
              filled
              label="Punkte bei falscher Antwort"
              type="number"
              v-model="editItem.wrongAnswerPoints"
              @keypress.enter.prevent="editOrCreate"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-select
              filled
              @change="questionTypeChange"
              label="Fragentyp"
              v-model="editItem.type"
              :items="QuestionTypes"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              filled
              label="Punkteverhalten bei falscher Antwort"
              v-model="editItem.wrongAnswerPointsBehaviour"
              :items="QuestionPointsBehaviours"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea filled rows="2" label="Notiz" v-model="editItem.notes">
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="mr-1"
          @click="editOrCreate"
          v-if="isCreate"
        >
          <v-icon class="mr-1">fas fa-plus</v-icon>
          Erstellen
        </v-btn>
        <v-btn @click="createDefaultGameQuestion" v-if="isCreate">
          <v-icon>fas fa-arrow-rotate-left</v-icon>
        </v-btn>
        <v-btn color="primary" class="mr-1" @click="editOrCreate" v-if="isEdit">
          <v-icon class="mr-1">fas fa-pen</v-icon>
          Editieren
        </v-btn>
        <v-btn @click="cancel" v-if="isEdit"> Abbrechen </v-btn>
      </div>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import QuestionPointsBehaviour, {
  questionPointsBehaviourTranslated,
} from "@/shared/QuestionPointsBehaviour";
import QuestionType, { questionTypeTranslated } from "@/shared/QuestionType";
import GameQuestion from "@/types/GameQuestion";
import RootEvents from "@/types/RootEvents";
import Vue from "vue";
import questionEditStore from "./QuestionEditStore";

export default Vue.extend({
  name: "EditQuestion",
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      QuestionPointsBehaviours: Object.keys(QuestionPointsBehaviour).map(
        (key) => ({
          text: questionPointsBehaviourTranslated(
            QuestionPointsBehaviour[key as keyof typeof QuestionPointsBehaviour]
          ),
          value: key,
        })
      ),
      QuestionTypes: Object.keys(QuestionType).map((key) => ({
        text: questionTypeTranslated(
          QuestionType[key as keyof typeof QuestionType]
        ),
        value: key,
      })),
      editItem: {
        id: -1,
        gameId: this.gameId,
        question: "",
        notes: "",
        answer: "",
        correctAnswerPoints: 300,
        wrongAnswerPoints: 100,
        wrongAnswerPointsBehaviour: QuestionPointsBehaviour.GivePointsToOthers,
        type: QuestionType.Buzzer,
      } as GameQuestion,
    };
  },
  methods: {
    cancel(): void {
      questionEditStore.clearSelection();
    },
    async editOrCreate() {
      await this.axios.put("/admin/question", {
        ...this.editItem,
      });
      this.createDefaultGameQuestion();
      (this.$refs.qTextArea as HTMLElement).focus();
      this.$root.$emit(RootEvents.GameQuestionsRefetch);
    },
    createDefaultGameQuestion(): void {
      this.editItem = {
        id: -1,
        gameId: this.gameId,
        question: "",
        notes: "",
        answer: "",
        correctAnswerPoints: 300,
        wrongAnswerPoints: 100,
        wrongAnswerPointsBehaviour: QuestionPointsBehaviour.GivePointsToOthers,
        type: QuestionType.Buzzer,
      };
    },
    questionTypeChange() {
      // Set default for EstimateAnswer
      if (this.editItem.type == QuestionType.EstimateAnswer) {
        this.editItem.wrongAnswerPoints = 0;
      }
    },
  },
  destroyed() {
    questionEditStore.clearSelection();
  },
  computed: {
    editQuestion(): GameQuestion | null | undefined {
      return questionEditStore.selectedQuestion;
    },
    isEdit(): boolean {
      return !!this.editQuestion;
    },
    isCreate(): boolean {
      return !this.isEdit;
    },
  },
  watch: {
    editQuestion(value) {
      if (!value) {
        // If selection is set to null, create default gameQuestion
        this.createDefaultGameQuestion();
      } else {
        // copy it, instead of using the reference.
        this.editItem = JSON.parse(JSON.stringify(value));
      }
    },
  },
});
</script>
