<template>
  <v-container v-if="loaded" class="d-flex flex-column h-100 gap-1">
    <div class="d-flex flex-row">
      <v-card
        width="200px"
        elevation="10"
        class="mx-auto my-auto"
        v-for="(p, i) in otherPlayers"
        :key="i"
      >
        <v-card-title class="mx-auto justify-center">
          {{ p.name }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center my-5">
            <v-icon>fas fa-medal</v-icon>
            <div class="mx-auto">{{ p.points }}</div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-sheet
      class="mx-auto my-2 d-flex pa-2 flex-grow-1 w-100"
      rounded="xl"
      elevation="10"
      style="min-height: 5rem; max-width: 800px"
    >
      <div class="d-flex flex-row my-auto w-100">
        <v-icon class="ml-4" x-large style="width: 5rem">
          fas fa-question
        </v-icon>
        <v-fade-transition>
          <h2 v-if="question" class="mx-auto" style="width: fit-content">
            {{ question }}
          </h2>
        </v-fade-transition>
      </div>
    </v-sheet>
    <v-sheet
      class="mx-auto my-2 d-flex pa-2 flex-grow-1 w-100"
      rounded="xl"
      elevation="10"
      style="min-height: 5rem; max-width: 800px"
    >
      <div class="d-flex flex-row my-auto w-100">
        <v-icon class="ml-4" x-large style="width: 5rem">
          fas fa-exclamation
        </v-icon>
        <v-fade-transition>
          <h2
            v-if="answer"
            class="mx-auto success--text"
            style="width: fit-content"
          >
            {{ answer }}
          </h2>
        </v-fade-transition>
      </div>
    </v-sheet>
    <v-card
      width="300px"
      class="mx-auto mb-auto mt-auto"
      v-if="loaded"
      elevation="10"
    >
      <v-card-title class="mx-auto justify-center">
        {{ player.name }}
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column justify-center my-5">
          <v-icon>fas fa-medal</v-icon>
          <div class="mx-auto">{{ player.points }}</div>
        </div>
        <p class="d-flex mt-2">
          <v-btn
            class="mx-auto"
            color="error"
            fab
            x-large
            dark
            @click="buzzer"
            :disabled="buzzered"
          >
            BUZZ
          </v-btn>
        </p>
        <v-text-field
          label="Antwort"
          :value="player.answer"
          @input="inputAnswer"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import LocalStorageKeys from "@/types/LocalStorageKeys";
import Vue from "vue";
import playerSessionStore from "./playerSessionStore";
export default Vue.extend({
  data() {
    return {
      gameId: localStorage.getItem(LocalStorageKeys.GAME_ID),
    };
  },
  async mounted() {
    await playerSessionStore.Initialize(
      localStorage.getItem(LocalStorageKeys.PLAYER_SECRET) ?? ""
    );
    await playerSessionStore.Subscribe();
  },
  destroyed() {
    playerSessionStore.Unsubscribe();
  },
  methods: {
    inputAnswer(a: string) {
      playerSessionStore.setPlayerAnswerAct(a);
    },
    async buzzer() {
      await playerSessionStore.buzzer();
    },
  },
  computed: {
    buzzered() {
      return playerSessionStore.buzzered;
    },
    loaded() {
      return playerSessionStore.loaded;
    },
    otherPlayers() {
      return playerSessionStore.otherPlayers;
    },
    player() {
      return playerSessionStore.currentPlayer;
    },
    question() {
      return playerSessionStore.question;
    },
    answer() {
      return playerSessionStore.answer;
    },
  },
});
</script>
