<template>
  <div v-if="player">{{ player.points }}</div>
</template>

<script lang="ts">
import Vue from "vue";
import spectateStore from "../views/admin/spectate/spectateStore";
export default Vue.extend({
  name: "rawUsername",
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      player: spectateStore.players.at(Number(this.userId) - 1),
    };
  },
});
</script>
