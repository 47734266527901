<template>
  <div>
    <div id="buzzerButton" :class="{ active: isActive }">
      <div>BUZZ</div>
    </div>
    <!-- <div
      :class="{
        'success--text': buzzerTimeRelative == 0,
        'error--text': buzzerTimeRelative > 0,
      }"
    >
      + {{ buzzerTimeRelative }} ms
    </div> -->
  </div>
</template>

<script lang="ts">
import spectateStore from "../views/admin/spectate/spectateStore";
import PlayerBuzzer from "@/types/PlayerBuzzer";
import Vue from "vue";
export default Vue.extend({
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      player: spectateStore.players.at(Number(this.userId) - 1),
    };
  },
  computed: {
    isActive(): boolean {
      return !!this.myBuzzer;
    },
    myBuzzer(): PlayerBuzzer | null {
      return (
        spectateStore.buzzers.find(
          (x: PlayerBuzzer) => x.playerId == this.player?.id
        ) ?? null
      );
    },
    buzzerTimeRelative(): number | null {
      const firstBuzzer = [...spectateStore.buzzers].sort(
        (a, b) => a.timestamp - b.timestamp
      );
      if (firstBuzzer.length == 0) {
        return null;
      }
      if (!this.myBuzzer) {
        return null;
      }
      return this.myBuzzer?.timestamp - firstBuzzer[0].timestamp;
    },
  },
  watch: {
    myBuzzer() {
      console.log(this.myBuzzer);
    },
  },
  // methods: {
  //   buzz() {
  //     this.isActive = this.player.;
  //   },
  //   unbuzz() {
  //     this.isActive = false;
  //   },
  // },
});
</script>

<style lang="scss">
#buzzerButton {
  display: flex;
  flex-direction: column;
  > div {
    margin: auto;
    height: fit-content;
  }
  aspect-ratio: 1;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 20px 40px;
  font-size: 25vh;
  font-weight: bold;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
  opacity: 0;
  transform: scale(0);
  width: 100vw;
}

#buzzerButton.active {
  opacity: 1;
  transform: scale(1);
}

#buzzerButton:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

#buzzerButton:active {
  background-color: #990000;
  transform: scale(0.95);
}
</style>
