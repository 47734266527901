import ApiResult from "@/shared/ApiResult";
import SocketSubscriptionTopic from "@/shared/SocketSubscriptionTopic";
import SocketIOProvider from "@/socketIO/SocketIOProvider";
import store from "@/store";
import Game from "@/types/Game";
import GameQuestion from "@/types/GameQuestion";
import Player from "@/types/Player";
import PlayerBuzzer from "@/types/PlayerBuzzer";
import axios from "axios";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export type PlayerWithBuzzer = Player & { buzzer: PlayerBuzzer };

@Module({ namespaced: true, name: "gameSessionStore", store, dynamic: true })
export class GameSessionStore extends VuexModule {
  private _gameId: number | null = null;
  private _players: Player[] = [];
  private _game: Game | null = null;
  private _allQuestions: GameQuestion[] = [];
  private _currentQuestion: GameQuestion | null = null;
  private _buzzers: PlayerBuzzer[] = [];

  public get buzzers() {
    return this._buzzers;
  }

  public get gameId() {
    return this._gameId;
  }

  public get game() {
    return this._game;
  }

  public get players() {
    return this._players;
  }

  public get allQuestions() {
    return this._allQuestions;
  }

  public get currentQuestion() {
    return this._currentQuestion;
  }

  @Mutation
  public setQuestions(q: GameQuestion[]) {
    this._allQuestions = q;
  }

  @Mutation
  public setGameId(gameId: number) {
    this._gameId = gameId;
  }

  @Mutation
  public setGame(game: Game) {
    this._game = game;
  }

  @Mutation
  private setPlayers(players: PlayerWithBuzzer[]) {
    this._players = players.map((x) => ({
      answer: x.answer,
      gameId: x.gameId,
      id: x.id,
      name: x.name,
      points: x.points,
      secret: x.secret,
    }));
    this._buzzers = players.filter((p) => !!p.buzzer).map((p) => p.buzzer);
  }

  @Mutation
  public resetBuzzer() {
    this._buzzers = [];
  }

  @Mutation
  public async playerBuzzered(payload: {
    playerId: number;
    timestamp: number;
  }) {
    const { playerId, timestamp } = payload;
    this._buzzers.push({
      playerId,
      timestamp,
    } as PlayerBuzzer);
  }

  @Mutation
  public async updateAnswerForPlayer(payload: {
    playerId: number;
    answer: string;
  }) {
    const { playerId, answer } = payload;
    const p = this._players.find((x) => x.id == playerId);
    if (p) {
      p.answer = answer;
    }
  }

  @Mutation
  private updateIndex(index: number | null) {
    if (this._game) {
      this._game.currentQuestionIndex = index;
    }
  }

  @Mutation
  private setCurrentQuestion(index: number | null) {
    if (index == null) {
      this._currentQuestion = null;
    } else {
      this._currentQuestion = this._allQuestions[index];
    }
  }

  @Action({ rawError: true })
  public async Initialize(gameId: number) {
    this.setGameId(gameId);
    await this.loadPlayers();
    const questions = await axios.get<
      ApiResult<{
        result: GameQuestion[];
        totalCount: number;
      }>
    >("/admin/questions", {
      params: {
        gameId,
      },
    });
    if (questions.data.success) {
      this.setQuestions(questions.data.data.result);
    }
    const result = await axios.get<ApiResult<Game>>("/admin/game", {
      params: {
        gameId,
      },
    });
    if (result.data.success) {
      this.setGame(result.data.data);
      this.setCurrentQuestion(result.data.data.currentQuestionIndex);
    }

    SocketIOProvider.subscribe<Player>(
      SocketSubscriptionTopic.GAME_PLAYER_CHANGED,
      `GAME_${this.gameId}`
    ).subscribe(this.updatePlayer.bind(this));

    SocketIOProvider.subscribe<unknown>(
      SocketSubscriptionTopic.GAME_RESET_BUZZER,
      `GAME_${this.gameId}`
    ).subscribe(this.resetBuzzer);

    SocketIOProvider.subscribe<{ playerId: number; answer: string }>(
      SocketSubscriptionTopic.ADMIN_PLAYER_ANSWER_UPDATE,
      `ADMIN`
    ).subscribe(this.updateAnswerForPlayer);

    SocketIOProvider.subscribe<{ playerId: number; timestamp: number }>(
      SocketSubscriptionTopic.ADMIN_PLAYER_BUZZERED,
      `ADMIN`
    ).subscribe(this.playerBuzzered);
  }

  @Action({ rawError: true })
  public async clickBonus(payload: { gameId: number; playerId: number }) {
    const { playerId, gameId } = payload;
    const result = await axios.post<ApiResult<Game & { players: Player[] }>>(
      "/admin/game/clickBonus",
      {
        gameId,
        playerId,
        questionId: this.currentQuestion?.id,
      }
    );
  }

  @Action({ rawError: true })
  public async clickAnswer(payload: {
    gameId: number;
    playerId: number;
    isCorrectAnswer: boolean;
  }) {
    const { playerId, gameId, isCorrectAnswer } = payload;
    const result = await axios.post<ApiResult<Game & { players: Player[] }>>(
      "/admin/game/clickAnswer",
      {
        gameId,
        playerId,
        isCorrectAnswer,
        questionId: this.currentQuestion?.id,
      }
    );
    console.log(result);
  }

  @Action({ rawError: true })
  public async setIndex(index: number | null) {
    if (this.game) {
      this.updateIndex(index);
      this.setCurrentQuestion(index);
      try {
        await axios.post<ApiResult<boolean>>("/admin/game/setIndex", {
          gameId: this.gameId,
          index,
        });
      } catch (error) {
        console.error(error);
      }
      try {
        await axios.post<ApiResult<boolean>>("/admin/game/resetBuzzer", {
          gameId: this.gameId,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  @Action({ rawError: true })
  public async revealQuestion() {
    if (this.currentQuestion) {
      await axios.post<ApiResult<boolean>>("/admin/game/revealQuestion", {
        gameId: this.gameId,
        questionId: this.currentQuestion?.id,
      });
    }
  }

  @Action({ rawError: true })
  public async revealAnswer() {
    if (this.currentQuestion) {
      await axios.post<ApiResult<boolean>>("/admin/game/revealAnswer", {
        gameId: this.gameId,
        questionId: this.currentQuestion.id,
      });
    }
  }

  //#region players
  @Action({ rawError: true })
  public async loadPlayers() {
    const result = await axios.get<ApiResult<PlayerWithBuzzer[]>>(
      "/admin/playersWithBuzzer",
      {
        params: {
          gameId: this.gameId,
        },
      }
    );
    if (result.data.success) {
      this.setPlayers(result.data.data);
    } else {
      console.error(result.data.data);
    }
  }

  @Action
  public updatePlayer(player: Player) {
    const p = this.players.find((x) => x.id == player.id);
    if (p) {
      p.points = player.points;
      p.answer = player.answer;
    }
  }
  //#endregion
}

const gameSessionStore = getModule(GameSessionStore);
export default gameSessionStore;
