<template>
  <div class="rawQuestion" v-if="question">{{ question }}</div>
</template>

<script lang="ts">
import Vue from "vue";
import spectateStore from "../views/admin/spectate/spectateStore";
export default Vue.extend({
  name: "rawUsername",
  computed: {
    question() {
      return spectateStore.question;
    },
  },
});
</script>

<style lang="scss">
.rawQuestion {
  font-size: 14vh;
}
</style>
