<template>
  <div class="rawAnswer" v-if="answer">{{ answer }}</div>
</template>

<script lang="ts">
import Vue from "vue";
import spectateStore from "../views/admin/spectate/spectateStore";
export default Vue.extend({
  name: "rawUsername",
  computed: {
    answer() {
      return spectateStore.answer;
    },
  },
});
</script>
<style lang="scss">
.rawAnswer {
  font-size: 14vh;
}
</style>
