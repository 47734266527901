<template>
  <div class="ma-4">
    <div>
      <strong>Punkte für den Spieler:</strong>
      {{ question.correctAnswerPoints }}
    </div>
    <div>
      <strong>Punkte für andere Spieler:</strong>
      {{ question.wrongAnswerPoints }}
    </div>
    <div>
      <strong>Verhalten:</strong>
      {{
        questionPointsBehaviourTranslated(question.wrongAnswerPointsBehaviour)
      }}
    </div>
    <div>
      <strong>Notizen:</strong>
      {{ question.notes }}
    </div>
  </div>
</template>

<script lang="ts">
import { questionPointsBehaviourTranslated } from "@/shared/QuestionPointsBehaviour";
import GameQuestion from "@/types/GameQuestion";
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";
export default Vue.extend({
  name: "InspectQuestionInfo",
  props: {
    question: {
      type: Object as PropType<GameQuestion>,
      required: true,
    },
  },
  methods: {
    questionPointsBehaviourTranslated,
  },
});
</script>
