<template>
  <v-container d-flex h-100 pt-5 style="gap: 1rem">
    <v-hover v-for="(game, i) in games" :key="i">
      <template v-slot:default="{ hover }">
        <v-card
          @click="() => navigateToGame(game.id)"
          class="GameCard transition-swing"
          :elevation="hover ? 24 : 6"
        >
          <v-card-title>
            <v-badge :value="game.id == currentGame" content="Current">
              {{ $t("Game.GameNumber", [game.id]) }}
            </v-badge>
          </v-card-title>
          <v-card-text class="d-flex flex-column h-100">
            <p>
              {{ game.description }}
            </p>
            <p>
              {{ $t("Game.CreatedAt", [$d(new Date(game.createdAt))]) }}
            </p>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  class="mt-auto ml-auto transition-swing"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click.prevent.stop="() => setAsCurrent(game)"
                  v-show="game.id != currentGame"
                >
                  <v-icon>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Game.SetAsCurrent") }}</span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
    <div class="ml-auto mt-auto">
      <v-btn @click="addGame">
        <v-icon class="mr-2"> fas fa-plus </v-icon>
        {{ $t("Game.Add") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts">
import ApiResult from "@/shared/ApiResult";
import Game from "@/types/Game";
import Vue from "vue";
import AddGameModalVue from "./modals/AddGameModal.vue";
export default Vue.extend({
  name: "AdminDashboard",
  data() {
    return {
      games: [] as Game[],
      currentGame: -1,
    };
  },
  methods: {
    async setAsCurrent(game: Game) {
      await this.axios.post<ApiResult<Game>>(
        "/admin/game/setCurrentGame",
        game
      );
      await this.getCurrentGame();
    },
    navigateToGame(id: string): void {
      this.$router.push({
        name: "adminGame",
        params: {
          gameIdString: id.toString(),
        },
      });
    },
    addGame() {
      this.$modal.show({
        title: this.$t("Game.AddGame").toString(),
        customComponentConfig: {
          data: {
            on: {
              refreshGames: () => {
                this.fetchGames();
              },
            },
          },
          vueConstructor: AddGameModalVue,
        },
        buttons: [],
      });
    },
    async fetchGames() {
      this.games = (
        await this.axios.get<ApiResult<Game[]>>("/admin/games")
      ).data.data;
    },
    async getCurrentGame() {
      const result = await this.axios.get<ApiResult<Game>>(
        "/game/getCurrentGame"
      );
      if (result.data.success) {
        this.currentGame = result.data.data.id;
      } else {
        console.error(result.data);
      }
    },
  },
  async mounted() {
    await Promise.all([this.getCurrentGame(), this.fetchGames()]);
  },
});
</script>

<style lang="scss">
.GameCard {
  width: 15rem;
  height: 15rem;
  &:hover {
    transform: translate(-0.25rem, -0.25rem);
  }
}
</style>
