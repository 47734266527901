<template>
  <div class="rawAnswer" v-if="player">{{ player.answer }}</div>
</template>

<script lang="ts">
import Vue from "vue";
import spectateStore from "../views/admin/spectate/spectateStore";
export default Vue.extend({
  name: "rawAnswer",
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      player: spectateStore.players.at(Number(this.userId) - 1),
    };
  },
});
</script>

<style lang="scss">
.rawAnswer {
  font-size: 17vh;
}
</style>
