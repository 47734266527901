<template>
  <div class="RawResults">
    <slot v-if="loaded"></slot>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import Vue from "vue";
import ApiResult from "@/shared/ApiResult";
import Game from "@/types/Game";
import spectateStore from "../views/admin/spectate/spectateStore";
export default Vue.extend({
  name: "rawContainer",
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    const result = await axios.get<ApiResult<Game>>("/game/getCurrentGame");
    if (result.data.success) {
      await spectateStore.Initialize(result.data.data.id);
      await spectateStore.Subscribe();
    } else {
      console.error(result.data);
    }
    this.loaded = true;
  },
  destroyed() {
    spectateStore.Unsubscribe();
  },
});
</script>

<style lang="scss">
.RawResults {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: "Roboto";
  font-size: 50vh;
  font-weight: 1000;
  > div {
    width: fit-content;
    height: fit-content;
    margin: auto;
    text-align: center;
  }
}
</style>
