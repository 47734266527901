<template>
  <div>
    <v-app v-if="!useRawLayout">
      <v-app-bar app color="primary">
        <h1>SchlauMeYeR</h1>
      </v-app-bar>

      <v-main>
        <modal-component>
          <router-view style="overflow-y: auto" />
        </modal-component>
      </v-main>
    </v-app>
    <raw-container v-else>
      <router-view />
    </raw-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import ModalComponent from "./plugins/modal/ModalComponent.vue";
import RawContainer from "./rawInfo/rawContainer.vue";
import SocketIOProvider from "./socketIO/SocketIOProvider";

export default Vue.extend({
  components: { ModalComponent, RawContainer },
  name: "App",
  mounted() {
    const socketIoProv = SocketIOProvider;
    socketIoProv.connect();
  },
  computed: {
    useRawLayout() {
      return this.$route.meta?.useRawLayout || false;
    },
  },
});
</script>
