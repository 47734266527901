import i18n from "@/i18n";
import ApiResult from "@/shared/ApiResult";
import SocketSubscriptionTopic from "@/shared/SocketSubscriptionTopic";
import SocketIOProvider from "@/socketIO/SocketIOProvider";
import store from "@/store";
import Player from "@/types/Player";
import PlayerBuzzer from "@/types/PlayerBuzzer";
import axios from "axios";
import { Subscription } from "rxjs";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { PlayerWithBuzzer } from "../gameSessionStore";

@Module({ namespaced: true, name: "spectateStore", store, dynamic: true })
export class SpectateStore extends VuexModule {
  private _gameId = 0;
  private _players: Player[] = [];
  private _loaded = false;
  private _subscriptions: Subscription[] = [];
  private _question = "";
  private _answer = "";
  private _buzzers: PlayerBuzzer[] = [];

  public get buzzers() {
    return this._buzzers;
  }
  public get loaded() {
    return this._loaded;
  }
  public get gameId() {
    return this._gameId;
  }

  public get players() {
    return this._players;
  }
  public get question() {
    return this._question;
  }
  public get answer() {
    return this._answer;
  }

  @Action
  public async Initialize(gameId: number) {
    this.setGameId(gameId);
    await this.loadPlayers();
    this.setLoaded();
  }

  @Action
  public async Subscribe() {
    let sub = SocketIOProvider.subscribe<Player>(
      SocketSubscriptionTopic.GAME_PLAYER_CHANGED,
      `GAME_${this.gameId}`
    ).subscribe(this.updatePlayer);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_UPDATE_QUESTION,
      `GAME_${this.gameId}`
    ).subscribe(this.setQuestion);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_UPDATE_ANSWER,
      `GAME_${this.gameId}`
    ).subscribe(this.setAnswer);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_RESET_QUESTION,
      `GAME_${this.gameId}`
    ).subscribe(this.resetQuestion);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<{ playerId: number; answer: string }>(
      SocketSubscriptionTopic.ADMIN_PLAYER_ANSWER_UPDATE,
      `ADMIN`
    ).subscribe(this.updateAnswerForPlayer);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<PlayerBuzzer>(
      SocketSubscriptionTopic.ADMIN_PLAYER_BUZZERED,
      `ADMIN`
    ).subscribe(this.playerBuzzered);
    this.addSubscription(sub);
  }

  @Mutation
  public async updateAnswerForPlayer(payload: {
    playerId: number;
    answer: string;
  }) {
    const { playerId, answer } = payload;
    const p = this._players.find((x) => x.id == playerId);
    if (p) {
      p.answer = answer;
    }
  }

  @Mutation
  public async playerBuzzered(payload: {
    playerId: number;
    timestamp: number;
  }) {
    const { playerId, timestamp } = payload;
    this._buzzers.push({
      playerId,
      timestamp,
    } as PlayerBuzzer);
  }

  @Mutation
  public Unsubscribe() {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  @Mutation
  public setQuestion(q: string) {
    this._question = q;
  }
  @Mutation
  public setAnswer(a: string) {
    this._answer = a;
  }
  @Mutation
  public resetQuestion(payload: any) {
    this._question = i18n
      .t("Game.Question.Placeholder", { number: payload })
      .toString();
    this._answer = "";
    this._buzzers = [];
  }

  @Mutation
  private addSubscription(sub: Subscription) {
    this._subscriptions.push(sub);
  }

  @Action({ rawError: true })
  public async loadPlayers() {
    const result = await axios.get<ApiResult<PlayerWithBuzzer[]>>(
      "/game/playersWithBuzzer",
      {
        params: {
          gameId: this.gameId,
        },
      }
    );
    if (result.data.success) {
      this.setPlayers(result.data.data);
    } else {
      console.error(result.data.data);
    }
  }

  @Action
  public updatePlayer(player: Player) {
    const p = this._players.find((x) => x.id == player.id);
    if (p) {
      p.points = player.points;
      p.answer = player.answer;
    }
  }

  // @Mutation
  // public setPlayerAnswer(answer: string) {
  //   const currentPlayer = this._players.find((x) => (x.id = this._playerId));
  //   if (currentPlayer) {
  //     currentPlayer.answer = answer;
  //   }
  // }

  @Mutation
  private setLoaded() {
    this._loaded = true;
  }

  @Mutation
  private setPlayers(players: PlayerWithBuzzer[]) {
    this._players = players.map((x) => ({
      answer: x.answer,
      gameId: x.gameId,
      id: x.id,
      name: x.name,
      points: x.points,
      secret: x.secret,
    }));
    this._buzzers = players.filter((p) => !!p.buzzer).map((p) => p.buzzer);
  }

  @Mutation
  public setGameId(id: number) {
    this._gameId = id;
  }
}

const spectateStore = getModule(SpectateStore);
export default spectateStore;
